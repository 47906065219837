import styled from 'styled-components';
import FirstBg from 'common/assets/image/syncStage/pattern-1@2x.png';
import SecondBg from 'common/assets/image/syncStage/pattern-2@2x.png';
import CardBG from 'common/assets/image/syncStage/box-img.png';

export const BenefitImg = styled.img`
  object-fit: contain;
  max-width: 500px;
  width: 100%;
`;

const BenefitsSectionWrapper = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
  z-index: 0;

  padding-bottom: 150px;
  padding-inline: 2rem;

  .benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }

  .benefit {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 60px;

    .text-box {
      text-align: left;
      max-width: 500px;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 10px;
      .text-box {
        h2,
        p {
          text-align: center;
        }
      }
    }
  }

  .first-bg {
    background-image: url(${FirstBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }

  .experience-card {
    background-image: url(${CardBG});
    background-repeat: no-repeat;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    width: 360px;
    padding: 20px;
    padding-top: 90px;
    padding-bottom: 60px;
    margin: 25px;

    @media (max-width: 575px) {
      width: 100%;
    }

    .divider {
      border: 2px solid #9800cd;
      width: 62px;
      margin-top: 45px;
      margin-bottom: 50px;
    }

    h3 {
      font-weight: 500;
      font-size: 25px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
    }
  }

  .first-bg {
    background-image: url(${FirstBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }

  .second-bg {
    background-image: url(${SecondBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 1000px;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }
`;

export default BenefitsSectionWrapper;

export const styles = {
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    pt: ['60px', '80px', '100px', '150px', '150px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  textStyle: {
    color: '#fff',
    maxWidth: 980,
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '150%',
  },
  titleStyle: {
    fontSize: ['26px', '36px', '40px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['10px', '20px', '30px', '40px', '40px'],
    lineHeight: '1.1',
  },
};

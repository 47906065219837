import styled from 'styled-components';
import BG from 'common/assets/image/syncStage/dots.png';

export const PartnerSectionWrapper = styled.section`
  padding: 10rem 2rem;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-position: 0px 100px;

  @media (max-width: 699px) {
    padding-inline: 0;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 1rem;

  @media (max-width: 699px) {
    flex-direction: column;
  }
`;

export const ImageWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 699px) {
    flex-direction: row-reverse;
    justify-content: flex-end;

    img {
      width: 25vw;
    }
  }
`;

export const HorizontalLine = styled.div`
  position: absolute;
  width: 80%;
  height: 1px;
  left: 10%;
  top: 90px;
  background-image: linear-gradient(to right, #01c8f9, #7f06b7);

  @media (max-width: 699px) {
    display: none;
  }
`;

export const styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
    mb: '40px',
  },
  title: {
    fontSize: ['26px', '34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '1rem',
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  textStyle: {
    color: '#fff',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: ['18px', '22px'],
    lineHeight: '1.5',
  },
  textStyleHeader1: {
    color: '#fff',
    textAlign: ['left', 'center'],
    fontSize: ['22px', '18px'],
  },
};

import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { REMOTE_SECTION_ID } from '../RemoteSessionSetup';
import Thumbnail5GFF from 'common/assets/image/syncStage/5gff-thumbnail.jpg';
import Thumbnail5GFFSmall from 'common/assets/image/syncStage/5gff-thumbnail-small.webp';
import PlayableBanner from 'components/Banner/PlayableBanner/PlayableBanner';
import BannerSideText from 'components/Banner/BannerSideText/BannerSideText';
import { BackgroundContainer, styles } from './styles';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';

const BannerSection = () => {
  const handleClickLearnMore = () => {
    const sessionElement = document.getElementById(REMOTE_SECTION_ID);
    if (sessionElement) {
      sessionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const { title, description, buttonWrapper } = styles;

  return (
    <PlayableBanner
      idle={
        <BackgroundContainer>
          <div className="section-idle">
            <LazyMedia src={Thumbnail5GFF} loadingSrc={Thumbnail5GFFSmall} alt="5GFF" />
            <div className="gradient-layer"></div>
          </div>
        </BackgroundContainer>
      }
      url={'https://www.youtube.com/watch?v=Cm-S46mguC0'}
    >
      <BannerSideText>
        <Heading {...title}>
          <React.Fragment>
            CAPTURE EVERY <span className="emphasize">LIGHTNING</span> IN A BOTTLE
          </React.Fragment>
        </Heading>
        <Text {...description}>
          When creative inspiration strikes, jam together, when you are not together with SyncStage
        </Text>
        <Box {...buttonWrapper}>
          <PrimaryButton title="LEARN MORE" onClick={handleClickLearnMore} />
        </Box>
      </BannerSideText>
    </PlayableBanner>
  );
};
export default BannerSection;

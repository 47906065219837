import styled from 'styled-components';
import PricingBg from 'common/assets/image/syncStage/pricing-bg.png';

export const RemoteSessionWrapper = styled.section`
  background-image: url(${PricingBg});
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  z-index: 0;
  padding: 6rem;
  display: flex;
  gap: 100px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .text-content {
    flex: 1;
  }
  .player-content {
    flex: 1.5;
    height: 600px !important;
  }

  @media (max-width: 1100px) {
    flex-direction: row;
    gap: 60px;

    .text-content,
    .player-content {
      flex: auto;
    }

    .text-content {
      h2,
      p {
        text-align: center;
      }
    }

    .player-content {
      height: 400px !important;
    }
  }
  @media (max-width: 767px) {
    padding-inline: 2rem;
  }
`;

export const styles = {
  title: {
    fontStyle: 'normal',
    fontSize: ['30px', '35px', '40px', '50px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  description: {
    fontSize: ['18px', '20px'],
    color: '#fff',
    lineHeight: '1.5',
    mb: '0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'left',
  },
};

import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import Time1 from 'common/assets/image/syncStage/latency1.png';
import TimeMobile1 from 'common/assets/image/syncStage/latency-mobile1.png';
import Time2 from 'common/assets/image/syncStage/latency2.png';
import TimeMobile2 from 'common/assets/image/syncStage/latency-mobile2.png';
import Time3 from 'common/assets/image/syncStage/latency3.png';
import TimeMobile3 from 'common/assets/image/syncStage/latency-mobile3.png';

import {
  styles,
  PartnerSectionWrapper,
  ImageWithTitle,
  ImagesContainer,
  HorizontalLine,
} from './onlineperformance.style';

const PlatformSection = () => {
  const { row, contentWrapper, title, textStyle, textStyleHeader1 } = styles;
  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading {...title} content="The online performing problem" />
          </Box>
        </Box>
        <Box className="text" {...row}>
          <Box>
            <Text
              {...textStyle}
              content="We are all frustrated with the current audio delay of zoom calls. Specifically for live performance we need consistent, low latency to deliver a synchronous experience."
            />
          </Box>
          <Box width="100%" style={{ position: 'relative', marginTop: 90 }}>
            <ImagesContainer>
              <ImageWithTitle>
                <Text {...textStyleHeader1} content={'VR GAMING'} />
                <picture>
                  <source media="(max-width: 699px)" srcSet={TimeMobile1} />
                  <source media="(min-width: 700px)" srcSet={Time1} />
                  <img src={Time1} alt="Latency time for vr gaming" />
                </picture>
              </ImageWithTitle>
              <ImageWithTitle>
                <Text
                  {...textStyleHeader1}
                  style={{ textTransform: 'uppercase' }}
                  content={'playing music synchronously'}
                />
                <picture>
                  <source media="(max-width: 699px)" srcSet={TimeMobile2} />
                  <source media="(min-width: 700px)" srcSet={Time2} />
                  <img src={Time2} alt="Latency time for playing music synchronously" />
                </picture>
              </ImageWithTitle>
              <ImageWithTitle>
                <Text {...textStyleHeader1} content={'CONVERSATION'} />
                <picture>
                  <source media="(max-width: 699px)" srcSet={TimeMobile3} />
                  <source media="(min-width: 700px)" srcSet={Time3} />
                  <img src={Time3} alt="Latency time for conversation" />
                </picture>
              </ImageWithTitle>
            </ImagesContainer>
            <HorizontalLine />
          </Box>
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

export default PlatformSection;

import React from 'react';
import BannerSection from 'containers/SyncStage/BannerMusic/BannerMusic';
import OnlineSection from 'containers/SyncStage/OnlinePerformance';
import CaseStudy5GFFSection from 'containers/SyncStage/CaseStudy5GFF';
import BenefitsSection from '../containers/SyncStage/Benefits';
import SignUpSection from '../containers/SyncStage/SignUp';
import { handleNewsletter, handleWhitepaper5GFF } from '../common/handlers';
import Layout from '../components/layout';
import RemoteSession from 'containers/SyncStage/RemoteSessionSetup';

const Music = () => {
  return (
    <Layout>
      <BannerSection />
      <OnlineSection />
      <BenefitsSection />
      <RemoteSession />
      <CaseStudy5GFFSection handleWhitepaper5GFF={handleWhitepaper5GFF} />
      <SignUpSection handleNewsletter={handleNewsletter} />
    </Layout>
  );
};
export default Music;

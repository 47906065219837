import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { CaseStudy5GFFWrapper, BackgroundContainer, styles } from './caseStudy5GFF.style';
import Thumbnail5GFF from 'common/assets/image/syncStage/5gff-thumbnail.jpg';
import Thumbnail5GFFSmall from 'common/assets/image/syncStage/5gff-thumbnail-small.webp';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';

const CaseStudy5GFFSection = ({ handleWhitepaper5GFF }) => {
  const { title, description, buttonWrapper } = styles;
  return (
    <CaseStudy5GFFWrapper id="case_study_5gff_section">
      <BackgroundContainer>
        <div className="background">
          <LazyMedia src={Thumbnail5GFF} loadingSrc={Thumbnail5GFFSmall} alt="5GFF case study" />
          <div className="gradient-layer"></div>
        </div>
        <div className="content-layer">
          <div>
            <Heading {...title}>
              <React.Fragment>
                <span className="emphasize">CASE STUDY</span>,<br />
                THE 5G FUTURE <br />
                FESTIVAL
              </React.Fragment>
            </Heading>
            <Text {...description}>
              <React.Fragment>
                The World&apos;s First Music Collaboration <br />
                Check out our whitepaper below.
              </React.Fragment>
            </Text>
          </div>
          <Box {...buttonWrapper}>
            <PrimaryButton title="DOWNLOAD WHITEPAPER" onClick={handleWhitepaper5GFF} />
          </Box>
        </div>
      </BackgroundContainer>
    </CaseStudy5GFFWrapper>
  );
};

export default CaseStudy5GFFSection;

import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import BenefitsSectionWrapper, { BenefitImg, styles } from './benefits.style';
import Image from 'common/components/Image';
import BuiltForSpeedImg from 'common/assets/image/syncStage/syncstage-built-for-speed.png';
import BuiltForAudioQualityImg from 'common/assets/image/syncStage/syncstage-built-for-audio-quality.png';
import BuiltToSupportDawImg from 'common/assets/image/syncStage/syncstage-built-to-support-daw.png';
import BuiltForInteroperability from 'common/assets/image/syncStage/syncstage-built-for-interoperability.png';

import Icon3 from 'common/assets/image/syncStage/icon-experience-3.png';
import Icon2 from 'common/assets/image/syncStage/icon-experience-2.png';
import Icon1 from 'common/assets/image/syncStage/icon-experience-1.png';

import Fade from 'react-reveal/Fade';

const BenefitsSection = () => {
  const { title, textStyle, titleStyle } = styles;

  return (
    <BenefitsSectionWrapper id="info_section">
      <Box>
        <Heading {...title} content="The benefits" />
      </Box>
      <div className="first-bg"></div>
      <div className="benefits">
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltForSpeedImg} alt="speed" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT FOR <span className="emphasize">SPEED</span>
              </Heading>
              <Text {...textStyle}>
                Design from the ground up to deliver consistent ultra-low audio latency for your customers allowing them
                to rehearse, jam or write songs remotely. SyncStage is 5-7x times faster than current WebRTC solutions.
              </Text>
            </Box>
          </div>
        </Fade>
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltForAudioQualityImg} alt="quality" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT FOR <span className="emphasize">QUALITY</span>
              </Heading>
              <Text {...textStyle}>
                Delivers high quality sound of that remote music collaboration session (24bit & 48k) directly to your
                DAW, so you can mix and master post the session.
              </Text>
            </Box>
          </div>
        </Fade>
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltToSupportDawImg} alt="daw" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT TO SUPPORT <span className="emphasize">DAW</span>
              </Heading>
              <Text {...textStyle}>
                Integrates directly into your Digital Audio Workstation of choice e.g. Ableton, Logic Pro, ProTools, FL
                Studio.
              </Text>
            </Box>
          </div>
        </Fade>
        <Fade bottom>
          <div className="benefit">
            <BenefitImg src={BuiltForInteroperability} alt="interoperability" />
            <Box className="text-box">
              <Heading {...titleStyle}>
                BUILT FOR <span className="emphasize">INTEROPERABILITY</span>
              </Heading>
              <Text {...textStyle}>
                Allows you to run remote sessions with musicians across iOS, Android, macOS and Windows.
              </Text>
            </Box>
          </div>
        </Fade>
        <Box>
          <Heading {...title}>
            What experience <br /> can now be offered?
          </Heading>
        </Box>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          <div className="second-bg"></div>
          <Fade bottom>
            <div className="experience-card">
              <Image src={Icon1} style={{ height: 90 }} />
              <div className="divider"></div>
              <h3>MUSIC COLLABORATION</h3>
              <p>
                When inspiration strikes, collaborate with songwriters and musicians when ‘writing for pitch’ or
                ‘writing for artist’ and capture those lightning in a bottle moments.
              </p>
            </div>
          </Fade>
          <Fade bottom>
            <div className="experience-card">
              <Image src={Icon2} style={{ height: 90 }} />
              <div className="divider"></div>
              <h3>Live performance</h3>
              <p>
                Allow performers to overdub existing mixes remotely and have a two-way experience with their fans and
                audience.
              </p>
            </div>
          </Fade>
          <Fade bottom>
            <div className="experience-card">
              <Image src={Icon3} style={{ height: 90 }} />
              <div className="divider"></div>
              <h3>Remote Learning</h3>
              <p>Provide a new digital revenue stream from remote students for your music education program</p>
            </div>
          </Fade>
        </div>
      </div>
    </BenefitsSectionWrapper>
  );
};

export default BenefitsSection;

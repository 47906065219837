import React from 'react';
import { RemoteSessionWrapper, styles } from './remoteSession.style';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import ReactPlayer from 'react-player';
import Fade from 'react-reveal/Fade';

export const REMOTE_SECTION_ID = 'remote_session_section';

function RemoteSession() {
  const { title, description } = styles;
  return (
    <RemoteSessionWrapper>
      <div className="text-content" id={REMOTE_SECTION_ID}>
        <Fade left>
          <Heading {...title}>SET UP RECORDING OF A REMOTE SESSION IN UNDER 5 MINUTES</Heading>
          <Text {...description}>
            The following video shows a step by step to set up SyncStage with your favorite DAW, showcased at NAMM 2024
          </Text>
        </Fade>
      </div>
      <ReactPlayer url={'https://youtu.be/6I7PYi9zDzM'} className="player-content" width={'100%'} playsinline />
    </RemoteSessionWrapper>
  );
}

export default RemoteSession;

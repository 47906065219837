import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { SignUpWrapper, styles } from './signUp.style';
import { Container } from 'react-bootstrap';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';

const SignUpSection = ({ handleNewsletter }) => {
  const { title, description, buttonWrapper } = styles;
  return (
    <SignUpWrapper id="sign_up_section">
      <Container className="contents">
        <Heading {...title}>Want to keep up with the latest news?</Heading>
        <Text {...description}>
          <React.Fragment>Sign up for SyncStage newsletter below</React.Fragment>
        </Text>
        <Box {...buttonWrapper}>
          <PrimaryButton title="SIGN UP FOR NEWSLETTER" onClick={handleNewsletter} />
        </Box>
      </Container>
    </SignUpWrapper>
  );
};

export default SignUpSection;

import styled from 'styled-components';

export const SignUpWrapper = styled.section`
  width: 100%;
  padding: 70px;
  .contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const styles = {
  title: {
    fontStyle: 'normal',
    fontSize: ['40px', '50px'],
    mb: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff',
    letterSpacing: '-0.025em',
    lineHeight: '1.1',
  },
  description: {
    fontSize: '22px',
    color: '#fff',
    lineHeight: '1.3',
    mb: '0',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'center',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'flex-start',
    mt: '75px',
  },
};
